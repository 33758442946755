<div class="flex justify-center my-5">
    <button class="mx-1 px-3 py-1 border rounded text-sm hover:bg-blue-500 hover:text-white"
        [class.disabled]="current_page === 1" (click)="onPageChange(1)">
        &lt;&lt;
    </button>

    <button class="mx-1 px-3 py-1 border rounded text-sm hover:bg-blue-500 hover:text-white"
        [class.disabled]="current_page === 1" (click)="onPageChange(current_page - 1)">
        &lt;
    </button>

    <button *ngFor="let page of pages_to_show" class="mx-1 px-3 py-1 border rounded text-sm" [ngClass]="{
        'hover:bg-blue-500 hover:text-white': current_page !== page,
        'bg-blue-500 text-white': current_page === page}" (click)="onPageChange(page)">
        {{ page }}
    </button>

    <button class="mx-1 px-3 py-1 border rounded text-sm hover:bg-blue-500 hover:text-white"
        [class.disabled]="current_page === total_pages" (click)="onPageChange(current_page + 1)">
        &gt;
    </button>

    <button class="mx-1 px-3 py-1 border rounded text-sm hover:bg-blue-500 hover:text-white"
        [class.disabled]="current_page === total_pages" (click)="onPageChange(total_pages)">
        &gt;&gt;
    </button>
</div>