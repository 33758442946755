<div class="flex justify-center mt-36">
  <form [formGroup]="loginForm" (ngSubmit)="login()" class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 w-[40%]">
    <div class="mb-4">
      <label for="email" class="block text-gray-700 text-sm font-bold mb-2">Email</label>
      <input type="email" id="email" formControlName="email" required
        class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
    </div>
    <div *ngIf="loginForm.get('email')?.errors && loginForm.get('email')?.touched" class="text-red-500 text-xs italic">
      <p *ngIf="loginForm.get('email')?.hasError('required')">Email es requerido.</p>
      <p *ngIf="loginForm.get('email')?.hasError('email')">Email no es valido.</p>
    </div>

    <div class="mb-4">
      <label for="password" class="block text-gray-700 text-sm font-bold mb-2">Password</label>
      <input type="password" id="password" formControlName="password" required
        class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
    </div>
    <div *ngIf="loginForm.get('password')?.errors && loginForm.get('password')?.touched"
      class="text-red-500 text-xs italic">
      <p *ngIf="loginForm.get('password')?.hasError('required')">Password es requerido.</p>
    </div>

    <button type="submit"
      class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">Log
      in</button>
  </form>
</div>