<div class="flex justify-center ">
    <form [formGroup]="registerForm" (ngSubmit)="register()"
        class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 w-[40%] m-4">
        <div class="mb-4">
            <label for="firstname" class="block text-gray-700 text-sm font-bold mb-2">Nombre</label>
            <input type="text" id="firstname" formControlName="firstname" required
                class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
        </div>
        <div *ngIf="registerForm.get('firstname')?.errors && registerForm.get('firstname')?.touched"
            class="text-red-500 text-xs italic">
            <p *ngIf="registerForm.get('firstname')?.hasError('required')">El nombre es requerido.</p>
        </div>

        <div class="mb-4">
            <label for="lastname" class="block text-gray-700 text-sm font-bold mb-2">Apellido</label>
            <input type="text" id="lastname" formControlName="lastname" required
                class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
        </div>
        <div *ngIf="registerForm.get('lastname')?.errors && registerForm.get('lastname')?.touched"
            class="text-red-500 text-xs italic">
            <p *ngIf="registerForm.get('lastname')?.hasError('required')">El apellido es requerido.</p>
        </div>

        <!-- Similar structure is repeated for other form fields... -->
        <div class="mb-4">
            <label for="email" class="block text-gray-700 text-sm font-bold mb-2">Email</label>
            <input type="email" id="email" formControlName="email" required
                class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
        </div>
        <div *ngIf="registerForm.get('email')?.errors && registerForm.get('email')?.touched"
            class="text-red-500 text-xs italic">
            <p *ngIf="registerForm.get('email')?.hasError('required')">Email es requerido.</p>
        </div>
        <p class="text-red-500 text-xs italic" *ngIf="registerForm.get('email')?.hasError('email')">Email no es valido.</p>

        <div class="mb-4">
            <label for="password" class="block text-gray-700 text-sm font-bold mb-2">Password</label>
            <input type="password" id="password" formControlName="password" required
                class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
        </div>
        <div *ngIf="registerForm.get('password')?.errors && registerForm.get('password')?.touched"
            class="text-red-500 text-xs italic">
            <p *ngIf="registerForm.get('password')?.hasError('required')">Password es requerido.</p>
        </div>
        <div *ngIf="registerForm.hasError('minLenghtPassword') && (registerForm.get('password')?.dirty || registerForm.get('password')?.touched)"
            class="text-red-500 text-xs italic">
            La contraseña debe tener al menos 8 caracteres.
        </div>

        <div class="mb-4">
            <label for="password_confirmation" class="block text-gray-700 text-sm font-bold mb-2">Confirm
                Password</label>
            <input type="password" id="password_confirmation" formControlName="password_confirmation" required
                class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
        </div>
        <div *ngIf="registerForm.get('password_confirmation')?.errors && registerForm.get('password_confirmation')?.touched"
            class="text-red-500 text-xs italic">
            <p *ngIf="registerForm.get('password_confirmation')?.hasError('required')">Confirm Password es requerido.
            </p>
        </div>
        <div *ngIf="registerForm.hasError('passwordMismatch') && (registerForm.get('password_confirmation')?.dirty || registerForm.get('password_confirmation')?.touched)"
            class="text-red-500 text-xs italic">
            Las contraseñas no coinciden.
        </div>
        <!-- <div class="mb-4">
            <label for="address" class="block text-gray-700 text-sm font-bold mb-2">Address</label>
            <input type="text" id="address" formControlName="address" required class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
        </div>
        <div *ngIf="registerForm.get('address')?.errors && registerForm.get('address')?.touched" class="text-red-500 text-xs italic">
            <p *ngIf="registerForm.get('address')?.hasError('required')">Address es requerido.</p>
        </div>
        
        <div class="mb-4">
            <label for="city" class="block text-gray-700 text-sm font-bold mb-2">City</label>
            <input type="text" id="city" formControlName="city" required class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
        </div>
        <div *ngIf="registerForm.get('city')?.errors && registerForm.get('city')?.touched" class="text-red-500 text-xs italic">
            <p *ngIf="registerForm.get('city')?.hasError('required')">City es requerido.</p>
        </div>
        
        <div class="mb-4">
            <label for="birthdate" class="block text-gray-700 text-sm font-bold mb-2">Birthday</label>
            <input type="date" id="birthdate" formControlName="birthdate" required class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
        </div>
        <div *ngIf="registerForm.get('birthdate')?.errors && registerForm.get('birthdate')?.touched" class="text-red-500 text-xs italic">
            <p *ngIf="registerForm.get('birthdate')?.hasError('required')">Birthday es requerido.</p>
        </div> -->

        <button type="submit"
            class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">Register</button>
    </form>
</div>